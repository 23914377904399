import "styles/pages/page-province.scss";

import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

const Province = ({ pageContext }) => {
	const data = pageContext.data;

	return (
		<Layout headerColor="#FFCE2E">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/province-top-bg.jpg").default}
				borderColor="#FFCE2E"
			/>
			<Breadcrumbs
				prevLocationLabel="Mapa regionów"
				currentLocationLabel={data.title}
			/>

			<section className="province">
				<div className="container">
					<div className="province-info">
						<h2 className="section-title">{data.title}</h2>

						<div>
							{data.acfProvince.provincePopulation && (
								<p>
									<strong>Populacja</strong>:{" "}
									{data.acfProvince.provincePopulation}
								</p>
							)}
							{data.acfProvince.provinceArea && (
								<p>
									<strong>Powierzchnia</strong>:{" "}
									{data.acfProvince.provinceArea} km
									<sup>2</sup>
								</p>
							)}
						</div>
					</div>

					<div className="province-item__wrapper">
						{data.acfProvince.provinceContent.map((item, index) => (
							<div className="province-item" key={index}>
								<div
									className={`row align-items-center ${
										index % 2 ? " row--reverse" : ""
									}`}
								>
									{item.provinceContentImage && (
										<div
											className={
												index % 2
													? "col-md-4 offset-md-1"
													: "col-md-5 offset-md-1"
											}
										>
											<div className="province-item__image">
												<img
													className="img-fluid"
													src={
														item
															.provinceContentImage
															?.sourceUrl
													}
													alt={data.title}
												/>
											</div>
										</div>
									)}
									<div
										className={
											index % 2
												? "col-md-6 offset-md-1"
												: "col-md-5 offset-md-1"
										}
										dangerouslySetInnerHTML={{
											__html: item.provinceContentDescription,
										}}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Province;
